// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-rum-detail-js": () => import("./../src/components/RumDetail.js" /* webpackChunkName: "component---src-components-rum-detail-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manifest-js": () => import("./../src/pages/manifest.js" /* webpackChunkName: "component---src-pages-manifest-js" */)
}

